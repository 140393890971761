<template>
  <div>
    <h1>Edit Company</h1>
    <b-card class="mt-3">
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Company Name"
              label-for="company-name"
            >
              <b-form-input
                v-model="companyDetails.name"
                placeholder="Company name"
                name="company-name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Phone No."
              label-for="company-phone"
            >
              <b-form-input
                v-model="companyDetails.homephone"
                name="company-phone"
                placeholder="0712345678"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Address"
              label-for="company-address"
            >
              <b-form-textarea
                v-model="companyDetails.address"
                name="company-address"
                placeholder=""
                rows="3"
                max-rows="8"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Contact"
              label-for="company-contact"
            >
              <b-row>
                <b-col sm="3">
                  <b-form-input
                    v-model="companyDetails.firstname"
                    name="company-firstname"
                    placeholder="Joe"
                  />
                </b-col>
                <b-col sm="3">
                  <b-form-input
                    v-model="companyDetails.lastname"
                    name="company-lastname"
                    placeholder="Bloggs"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Postcode"
              label-for="company-postcode"
            >
              <b-form-input
                v-model="companyDetails.postcode"
                placeholder="OB1 W4N"
                name="company-postcode"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="saveChanges"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="cancelEdit"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>

import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BButton, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import jwt from '@/auth/jwt/sclUseJwt'
import swal from 'sweetalert2'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: '',
      companyDetails: {
        name: '',
        homephone: '',
        address: '',
        firstname: '',
        lastname: '',
        postcode: '',
      },
    }
  },
  created() {
    this.clearCompanyDetails()
    this.id = this.$route.query.id
    if (this.id.length > 0) {
      jwt.axiosIns.get(`/clientdetails/${this.id}/`)
        .then(response => {
          this.companyDetails.name = response.data.name
          this.companyDetails.firstname = response.data.firstname
          this.companyDetails.lastname = response.data.lastname
          this.companyDetails.homephone = response.data.homephone
          this.companyDetails.address = response.data.address
          this.companyDetails.postcode = response.data.postcode
        })
        .catch(e => {
          console.error(e)
        })
    }
  },
  methods: {
    clearCompanyDetails() {
      this.companyDetails.name = ''
      this.companyDetails.homephone = ''
      this.companyDetails.address = ''
      this.companyDetails.firstname = ''
      this.companyDetails.lastname = ''
      this.companyDetails.postcode = ''
    },
    saveChanges() {
      const data = JSON.stringify(this.companyDetails)
      jwt.axiosIns.put(`/clientdetails/${this.id}/`, data)
        .then(() => {
          swal.fire({
            title: 'Success!',
            text: `Client details for '${this.companyDetails.name}' have been saved.`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(e => {
          swal.fire({
            title: 'Error',
            text: `There was an error saving the details for '${this.companyDetails.name}'. Please check for missing fields then try again.`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          console.error(e)
        })
    },
    cancelEdit() {
      this.$router.go(-1) // Return to previous page
    },
  },
}
</script>

<style scoped>

</style>
